@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.CaseStudiesSmall';

#{$name} {    
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    &-quote {
        line-height: 1.2;
        margin-bottom: 0;
    }

    &-quoteFooter {
        margin-top: .8rem;
    }

    &-quoteText {

        &:after {
            content: "“";
            font-size: 2rem;
            line-height: 0;
            position: relative;
            vertical-align: -.5rem;

            @include media-breakpoint-up(sm) {
                font-size: 5rem;
                vertical-align: -2rem;
            }
        }
    }

    &-indicators {
        margin-top: 1rem;
    }
}
